<template>
  <base-section
    id="values"
    class="grey lighten-4 pt-1"
  >
    <base-section-heading
      title="Como começar?"
      class="mt-16"
    >
        Ficou alguma dúvida sobre o acompanhamento ou você já deciciu que a
        <br>melhor opção é investir em sua saúde?<br>
        <strong>Entre em contato comigo:</strong>
    </base-section-heading>
    <v-container>
      <v-row
        no-gutters
        align="center"
        justify="center"
      >
        <v-col
          class="text-center mb-0"
          cols="12"
          md="6"
        >
          <v-text-field
            label="Envie sua mensagem e comece agora mesmo!"
            solo
            v-model="message"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row
        no-gutters
        align="center"
        justify="center"
      >
        <v-col
          class="text-right mt-0"
          cols="12"
          md="6"
        >
          <v-btn
            class="mt-0"
            target="_blank"
            large
            :outlined="dark"
            :color="dark ? 'white' : 'success'"
            :href="whatsappChat"
          >
            <v-icon
              class=" mr-3"
            >
              mdi-whatsapp
            </v-icon>
             Enviar
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>
<script>
  export default {
    name: 'SectionNoValues',

    data: () => ({
      offer: false,
      message: '',
    }),

    computed: {
      whatsappChat: function () {
        return 'https://api.whatsapp.com/send?phone=554991483551&text=' +
          (this.message.length > 0 ? this.message : 'Olá, tenho interesse no acompanhamento nutricional')
      },
      prices: function () {
        return [
          {
            local: 'Online',
            icon: 'mdi-wifi',
            month: [350, 350],
            trimester: [950, 950],
            couple: [262.5, 262.5],
            special: false,
          },
          {
            local: 'Chapecó/SC',
            month: [450, 450, 400],
            trimester: [1350, 1250, 1100],
            couple: [337.50, 337.5, 300],
            special: this.offer,
          },
          {
            local: 'São Carlos/SC',
            month: [400, 400, 350],
            trimester: [1200, 1100, 950],
            couple: [300, 300, 262.5],
            special: this.offer,
          },
          {
            local: 'Palmitos/SC',
            month: [400, 400, 350],
            trimester: [1200, 1100, 950],
            couple: [300, 300, 262.5],
            special: this.offer,
          },
        ]
      },
    },
  }
</script>
